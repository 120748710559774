import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Modal, Row, Col, Form } from "react-bootstrap";
import JSZip from "jszip";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { deleteFromAPI, getFromAPI, postToAPI, putToAPI } from '../Utils/utils';
import { toast } from 'react-toastify';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

export default function DestinationMapping({activeTab}) {
    // Modal
    const [showDestinationMappingModal, setshowDestinationMappingModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [DestinationMappingData, setDestinationMappingData] = useState();
    const [DestinationData, setDestinationData] = useState();
    const [VehicleAssignmentData, setVehicleAssignmentData] = useState();
    const [VehicleMappingData, setVehicleMappingData] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [EditDestinationMappingID, setEditDestinationMappingID] = useState();
    const [DeleteDestinationMappingID, setDeleteDestinationMappingID] = useState();
    const [validated, setValidated] = useState(false);
    const InitialDestinationMapInputData = {
        name: "",
        destinationId: 0,
        vehicle_mapping_id: 0,
        isActive: true,
    }
    const [DestinationMapInputData, setDestinationMapInputData] = useState(InitialDestinationMapInputData);

    const fetchDestinationMappingData = () => {
        getFromAPI('DestinationMapping/').then(data => {
            setDestinationMappingData(data);
        }).catch(error => {
            console.error('Error fetching Destination mapping data:', error);
        });
    }

    const fetchVehicleAssignmentData = () => {
        getFromAPI(`VehicleAssignment/?isActive=True`).then(data => {
            setVehicleAssignmentData(data);
        }).catch(error => {
            console.error('Error fetching vehicle assignment data:', error);
        });
    }

    const fetchVehicleMappingData = () => {
        getFromAPI('vehicleMapping/').then(data => {
            setVehicleMappingData(data);
        }).catch(error => {
            console.error('Error fetching vehicle assignment data:', error);
        });
    }

    const fetchDestinationData = () => {
        getFromAPI('Destination/').then(data => {
            setDestinationData(data);
        }).catch(error => {
            console.error('Error fetching destination data:', error);
        });
    }

    const handleshoweditDestinationMappingModal = (title, id) => {
        setIsEdit(true);
        setEditDestinationMappingID(id);
        getFromAPI(`DestinationMapping/?id=${id}`).then(dataArr => {
            let data = dataArr[0];
            setDestinationMapInputData({...DestinationMapInputData, name:data.name, destinationId:data.destinationId, vehicle_mapping_id:data.vehicle_mapping_id, isActive:data.isActive});
        });
        handleshowDestinationMappingModal(title);
    }

    const handleshowDestinationMappingModal = (title) => {
        setModalTitle(title);
        setshowDestinationMappingModal(true);
    };
    const handleCloseModal = () => {
        UpdateDestinationMapDataTable();
        setEditDestinationMappingID(null);
        setIsEdit(false);
        setValidated(false);
        setshowDeleteModal(false);
        setDeleteDestinationMappingID(null);
        setDestinationMapInputData(InitialDestinationMapInputData);
        setshowDestinationMappingModal(false);
    };

    const handleDeleteData = (id) => {
        // Set the selected id and show the modal
        setDeleteDestinationMappingID(id);
        setshowDeleteModal(true);
    };

    const handleConfirmDelete = async() => {
        try {
            const Response = await deleteFromAPI(`DestinationMapping/${DeleteDestinationMappingID}/`);
            if (Response.status) {
                toast.success('Destination map data Successfully deleted');
                handleCloseModal();
            } else {
                toast.error('Failed to delete destination map data');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An unexpected error occurred');
        }
    };

    const handleAddDestinationMapping = async (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
        } else {
            e.preventDefault(); // Prevent page reload
            setValidated(true);
           
            if(DestinationMapInputData.name.trim() === "") {
                toast.error("Enter valid destination mapping name");
                return
            }
            if(isEdit) {
                try {
                    const Response = await putToAPI(`DestinationMapping/${EditDestinationMappingID}/`, DestinationMapInputData);
                    if (Response.status) {
                        toast.success('Destination Mapping data Successfully updated');
                        handleCloseModal();
                    } else {
                        toast.error('Failed to update Destination Mapping data');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            } else {
                const isDuplicateName = DestinationMappingData.some(dmape => {
                    return (
                        dmape.name.toLowerCase() === DestinationMapInputData.name.toLowerCase() &&
                        (!isEdit || dmape.id !== EditDestinationMappingID)
                    );
                });
    
                if(isDuplicateName) {
                    toast.error("Destination mapping name already exists");
                    return
                }

                try {
                    const Response = await postToAPI("DestinationMapping/", DestinationMapInputData);
                    if (Response.status) {
                        toast.success('Destination Mapping data Added Successfully');
                        handleCloseModal();
                    } else {
                        toast.error('Failed to add Destination Mapping data');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            }
        }
    }

    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (DestinationMappingData && VehicleMappingData && DestinationData && !$.fn.dataTable.isDataTable("#DestinationMappingDataTable")) {
            $("#DestinationMappingDataTable").DataTable({
                dom: 'Bflrtip',
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    }
                ],
                data: DestinationMappingData.map((data, i) => [
                    i + 1,
                    data?.name || 'NA',
                    VehicleMappingData.find(va => parseInt(va.id) === parseInt(data.vehicle_mapping_id))?.name || 'NA',
                    DestinationData.find(d => parseInt(d.id) === parseInt(data.destinationId))?.name || 'NA',
                    data.id,
                    data.isActive
                ]),
                columns: [
                    { title: "No." },
                    { title: "Destination Mapping Name" },
                    { title: "Vehicle Assignment" },
                    { title: "Destinations" },
                    {
                        title: "Status",
                        render: function (data, type, row) {
                            // TODO: Get status color & text.
                            let statuColor = row[5] ? 'success' : 'danger';
                            let statusText = row[5] ? 'Active' : 'in-Active';
                            return `
                            <span class="badge badge-soft-${statuColor}">${statusText}</span>
                          `;
                        }
                    },
                    {
                        title: "Action",
                        render: function (data, type, row) {
                            return `
                                <div class="d-flex">
                                    <button class="btn btn-soft-primary table-btn me-2"><i class="bi bi-pencil-square edit-destination-mapping" data-dm-id="${row[4]}"></i></button>
                                    <button class="btn btn-soft-danger table-btn"><i class="bi bi-trash3 delete-destination-mapping" data-dm-id="${row[4]}"></i></button>
                                </div>
                            `;
                        }
                    },
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
            // Event delegation to handle delete action
            document.querySelector('#DestinationMappingDataTable tbody').addEventListener('click', function (event) {
                if (event.target && event.target.classList.contains('edit-destination-mapping')) {
                    const dm_id = event.target.getAttribute('data-dm-id');
                    handleshoweditDestinationMappingModal("Edit Destination Mapping", dm_id);
                }
                if (event.target && event.target.classList.contains('delete-destination-mapping')) {
                    const dm_id = event.target.getAttribute('data-dm-id');
                    handleDeleteData(dm_id);
                }
            });

        }
    }, [DestinationMappingData, VehicleMappingData, DestinationData]);

    const UpdateDestinationMapDataTable = () => {
        const DMDT = $('#DestinationMappingDataTable').DataTable();
        getFromAPI("DestinationMapping/")
            .then(response => {
                setDestinationMappingData(response);
                let DTData = response.map((data, i) => [
                    i + 1,
                    data.name,
                    VehicleMappingData.find(va => parseInt(va.id) === parseInt(data.vehicle_mapping_id)).name,
                    DestinationData.find(d => parseInt(d.id) === parseInt(data.destinationId)).name,
                    data.id,
                    data.isActive
                ]);
                DMDT.clear().rows.add(DTData).draw();
            })
            .catch(error => {
            console.error('Error:', error);
            });
    }

    useEffect(()=>{
        fetchDestinationMappingData();
        fetchVehicleAssignmentData();
        fetchDestinationData();
        fetchVehicleMappingData();
    },[]);

    useEffect(()=>{
        fetchDestinationMappingData();
        fetchVehicleAssignmentData();
        fetchDestinationData();
        fetchVehicleMappingData();
    },[activeTab]);

    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Destination Mapping</Card.Title>
                <Button variant="primary" onClick={() => handleshowDestinationMappingModal("Add Destination Mapping")}>
                    <i className="bi bi-plus-square me-2"></i> Add Destination Mapping
                </Button>
            </div>

            <Table responsive bordered className="mb-0 table-nowrap" id="DestinationMappingDataTable">
                <thead className="table-light">
                </thead>
            </Table>

            {/* Vehicle Modal */}
            <Modal centered size="lg" show={showDestinationMappingModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={(e) => handleAddDestinationMapping(e)}>
                    <Modal.Body className="pb-0">
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Mapping Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Mapping Name..." name="vehiclename" value={DestinationMapInputData.name} onChange={(e)=>setDestinationMapInputData({...DestinationMapInputData, name:e.target.value})} required isInvalid={validated && !DestinationMapInputData.name.trim()}/>
                                    <Form.Control.Feedback type="invalid">
                                        Please enter destination mapping name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                    <Form.Group className="form-space">
                                        <Form.Label>Vehicle Assignment</Form.Label>
                                        <Form.Select aas="select" name="VehicleType" value={DestinationMapInputData.vehicle_mapping_id} onChange={(e)=>setDestinationMapInputData({...DestinationMapInputData, vehicle_mapping_id:e.target.value})} required isInvalid={validated && (DestinationMapInputData.vehicle_mapping_id === 0)} >
                                            <option value="" >Select Vehicle Assignment</option>
                                            {VehicleMappingData && VehicleMappingData.filter(vm => vm.isActive) // Filter only the active vehicles
                                                .map((vm) => (
                                                    <option key={vm.id} value={vm.id}>
                                                        {vm.name}
                                                    </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please select vehicle assignment.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="form-space">
                                        <Form.Label>Destination</Form.Label>
                                        <Form.Select aas="select" name="DriverName" value={DestinationMapInputData.destinationId} onChange={(e)=>setDestinationMapInputData({...DestinationMapInputData, destinationId:e.target.value})} required isInvalid={validated && (DestinationMapInputData.destinationId === 0)} >
                                            <option value="" >Select Destination</option>
                                            {DestinationData && DestinationData.map((destination) => (
                                                <option key={destination.id} value={destination.id}>
                                                    {destination.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please select destination.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Check
                                    type="checkbox"
                                    id="default-checkbox"
                                    label="Is Active"
                                    checked={DestinationMapInputData.isActive}
                                    onChange={(e)=>setDestinationMapInputData({...DestinationMapInputData, isActive:e.target.checked})}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="mt-1">
                        <Button variant="primary" type='submit' >Save</Button>
                        <Button variant="light" onClick={handleCloseModal}>Close</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <Modal centered show={showDeleteModal} onHide={() => setshowDeleteModal(false)}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto ">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={() => setshowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger w-50" onClick={handleConfirmDelete}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
