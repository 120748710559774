import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Modal, Row, Col, Form } from "react-bootstrap";
import JSZip from "jszip";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { postToAPI, getFromAPI, putToAPI, deleteFromAPI } from "../Utils/utils";
import { toast,ToastContainer } from "react-toastify";

export default function Destinations({ activeTab }) {
    const [showDestinationModal, setShowDestinationModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [destinationInputData, setDestinationInputData] = useState({
        name: '',
        wardId: '',
        isActive: true,
        qrCodeId: ''
    });
    const [destinationIdToEdit, setDestinationIdToEdit] = useState(null);
    const [destinationIdToDelete, setDestinationIdToDelete] = useState(null);
    const [destinationsData, setDestinationsData] = useState();
    const [wardsData, setWardsData] = useState();
    const [QRCodeData, setQRCodeData] = useState();

    // Fetch destinations data
    const fetchDestinationData = () => {
        getFromAPI(`destination/`).then(data => {
            if (data) {
                setDestinationsData(data);
            } else {
                console.error('Unexpected data structure:', data);
            }
        }).catch(error => {
            console.error('Error fetching destinations:', error);
        });
    };

   // Fetch wards data
    const fetchWardsData = () => {
        getFromAPI(`ward/`).then(data => {
            if (data) {
                setWardsData(data); // Store the full data response
            } else {
                console.error('Unexpected data structure:', data);
            }
        }).catch(error => {
            console.error('Error fetching wards:', error);
        });
    };
    
    const fetchQRCodeData = () => {
        getFromAPI(`QRCode/`).then(data => {
            if (data) {
                setQRCodeData(data); // Store the full data response
            } else {
                console.error('Unexpected data structure:', data);
            }
        }).catch(error => {
            console.error('Error fetching wards:', error);
        });
    };

    // Initial data fetching
    useEffect(() => {
        fetchDestinationData();
        fetchWardsData();
        fetchQRCodeData();
    }, []);

    useEffect(() => {
        fetchDestinationData();
        fetchWardsData();
        fetchQRCodeData();
    }, [activeTab]);

    // Initialize the DataTable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    useEffect(() => {
        if (Array.isArray(destinationsData) && destinationsData.length > 0 && wardsData?.ward && Array.isArray(wardsData.ward) && wardsData.ward.length > 0 && QRCodeData) {
            const tableElement = $("#destinationDataTable");
    
            // Clear existing DataTable instance
            if ($.fn.dataTable.isDataTable(tableElement)) {
                tableElement.DataTable().clear().destroy();
            }
    
            // Initialize new DataTable
            tableElement.DataTable({
                dom: 'Bflrtip',
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf'
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print'
                    }
                ],
                data: destinationsData.map((data, i) => {
                    const wardName = wardsData.ward.find(ward => ward.id === data.wardId)?.name || 'N/A';
                    const QRCode = QRCodeData.find(qr => qr.id === data.qrCodeId)?.code || 'N/A';
    
                    return [
                        i + 1,
                        data.name || 'N/A',
                        wardName, // Display the corresponding ward name
                        QRCode,
                        data.isActive
                            ? `<span class="badge badge-soft-success">Active</span>`
                            : `<span class="badge badge-soft-danger">Inactive</span>`,
                        `
                            <div class="d-flex">
                                <button class="btn btn-soft-primary table-btn me-2 edit-destination" data-dest-id="${data.id}">
                                    <i class="bi bi-pencil-square"></i>
                                </button>
                                <button class="btn btn-soft-danger table-btn delete-destination" data-dest-id="${data.id}">
                                    <i class="bi bi-trash3"></i>
                                </button>
                            </div>
                        `
                    ];
                }),
                columns: [
                    { title: "No." },
                    { title: "Destinations Name" },
                    { title: "Ward" },
                    { title: "QR Code" },
                    { title: "Status" },
                    { title: "Action" }
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
    
            // Handle edit and delete button click events
            tableElement.on('click', '.edit-destination', function () {
                const destinationId = $(this).data('dest-id');
                handleShowDestinationModal("Edit Destination", destinationId, true);
            });
    
            tableElement.on('click', '.delete-destination', function () {
                const destinationId = $(this).data('dest-id');
                setDestinationIdToDelete(destinationId);
                setShowDeleteModal(true);
            });
        }
    }, [destinationsData, wardsData, QRCodeData]);
    
    
    // Open the modal for adding or editing a destination
    const handleShowDestinationModal = (title, destinationId = null, isEdit = false) => {
        setModalTitle(title);
        setShowDestinationModal(true);

        if (isEdit && destinationId) {
            setDestinationIdToEdit(destinationId);

            getFromAPI('destination/').then(data => {
                if (Array.isArray(data)) {
                    const destinationData = data.find(destination => destination.id === destinationId);

                    if (destinationData) {
                        setDestinationInputData({
                            name: destinationData.name || '',
                            wardId: destinationData.wardId || '',
                            qrCodeId : destinationData.qrCodeId || '',
                            isActive: destinationData.isActive
                        });
                    } else {
                        console.error('Destination not found in data:', destinationId);
                    }
                } else {
                    console.error('Unexpected data structure:', data);
                }
            }).catch(error => {
                console.error('Error fetching destinations:', error);
            });
        } else {
            setDestinationIdToEdit(null);
            setDestinationInputData({
                name: '',
                wardId: '',
                qrCodeId : '',
                isActive: true
            });
        }
    };

    const handleCloseModal = () => {
        setShowDestinationModal(false);
        setValidated(false); // Reset validation state
        setDestinationInputData({
            name: "",
            wardId: "",
            qrCodeId : "",
            isActive: true,
        }); // Reset the form data
        setDestinationIdToEdit(null); // Reset the edit state
    };

    // Save destination
    const handleSaveDestination = async (e) => {
        e.preventDefault(); // Prevent page reload
        const form = e.currentTarget;
        const formIsValid = form.checkValidity();

        if (!formIsValid) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setValidated(true);
            const isDuplicateName = destinationsData.some(destination => {
                return (
                    destination.name.toLowerCase() === destinationInputData.name.toLowerCase() &&
                    (!destinationIdToEdit || destination.id !== destinationIdToEdit)
                );
            });

            if (isDuplicateName) {
                toast.error('A destination with this name already exists.');
                return;
            }

        try {
            let response;
            if (destinationIdToEdit) {
                response = await putToAPI(`destination/${destinationIdToEdit}/`, destinationInputData);
                if (response.status) {
                    fetchDestinationData();
                    toast.success("Destination updated successfully",'success')
                }
            } else {
                response = await postToAPI("destination/", destinationInputData);
                if (response.status) {
                    toast.success("Destination Added successfully",'success')
                    fetchDestinationData();
                }
            }
            handleCloseModal();
        } catch (error) {
            console.error('Error:', error);
        }
    }
    };

    // Confirm delete
    const handleConfirmDelete = async () => {
        try {
            let destinationMapData = await getFromAPI(`DestinationMapping/?destinationId=${destinationIdToDelete}`);
            let destinationAssignData = await getFromAPI(`DestinationAssignment/?destination_id=${destinationIdToDelete}`);
            // Delete Destination only if it is not mapped to any vehicle
            if(destinationMapData.length === 0 && destinationAssignData.length === 0) {
                await deleteFromAPI(`destination/${destinationIdToDelete}/`);
                toast.success("Destination Deleted successfully",'success')
                setShowDeleteModal(false);
                fetchDestinationData();
            } else {
                toast.error("Selected destination is assigned in destination mapping. Make sure that destination is not assigned before delete.", 'error');
            }
        } catch (error) {
            console.error('Error deleting destination:', error);
            toast.error("Unexpected error",'error')
        }
    };

    const handleCheckboxChange = (event) => {
        setDestinationInputData({ ...destinationInputData, isActive: event.target.checked });
    };
    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Destinations</Card.Title>
                <Button variant="primary" onClick={() => handleShowDestinationModal("Add Destination")}>
                    <i className="bi bi-plus-square me-2"></i> Add Destination
                </Button>
            </div>

            <Table responsive bordered className="mb-0 table-nowrap" id="destinationDataTable">
                <thead className="table-light">
                </thead>
            </Table>

            {/* Add/Edit Destination Modal */}
            <Modal centered size="lg" show={showDestinationModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={(e) => handleSaveDestination(e)}>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Destination Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Destination Name"
                                    value={destinationInputData.name}
                                    onChange={e => setDestinationInputData({ ...destinationInputData, name: e.target.value })} required isInvalid={validated && !destinationInputData.name}
                                />
                                <Form.Control.Feedback type="invalid">Destination Name is required.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Ward</Form.Label>
                                <Form.Select
                                value={destinationInputData.wardId}
                                onChange={e => setDestinationInputData({ ...destinationInputData, wardId: e.target.value })} required isInvalid={validated && !destinationInputData.wardId}
                            >
                                <option value="">Select Ward</option>
                                {wardsData && wardsData.ward && wardsData.ward.map((ward) => (
                                    <option key={ward.id} value={ward.id}>
                                        {ward.name}
                                    </option>
                                ))} 
                            </Form.Select>

                                <Form.Control.Feedback type="invalid">Ward is required.</Form.Control.Feedback>

                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>QR code</Form.Label>
                                <Form.Select
                                value={destinationInputData.qrCodeId}
                                onChange={e => setDestinationInputData({ ...destinationInputData, qrCodeId: e.target.value })} required isInvalid={validated && !destinationInputData.qrCodeId}
                            >
                                <option value="">Select QR Code</option>
                                {QRCodeData && QRCodeData.map((qr) => (
                                    <option key={qr.id} value={qr.id}>
                                        {qr.code}
                                    </option>
                                ))} 
                            </Form.Select>

                                <Form.Control.Feedback type="invalid">QR code is required.</Form.Control.Feedback>

                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    label="Active"
                                    checked={destinationInputData.isActive}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type='submit'>Save</Button>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
                </Form>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                        <Button variant="danger w-50" onClick={handleConfirmDelete}>Delete</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
